.portfolio-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  margin: 1rem;
}

.portfolio-block {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  width: 100%; */
  display: grid;
  width: 100%;
  max-width: 950px;
  grid-template-columns: repeat(auto-fit, minmax(max(250px, 100%/3), 1fr));
}

.portfolio-item {
  border: 3px solid green;
  text-align: center;
  box-sizing: border-box;
  /* margin: 1rem; */
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  clip-path: content-box;
  cursor: pointer;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.portfolio-full {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  /* flex-direction: row;
  justify-content: center; */
  z-index: 2;
  /* opacity: 0; */
  animation: 0.5s fadein none;
  transition: opacity 0.5s;
}

.portfolio-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid green; */
  min-height: 100px;
  max-height: 400px;
}

.portfolio-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px solid blue; */
  min-height: 100px;
  max-height: 400px;
}

.portfolio-subrow {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* border: 2px solid red; */
  align-items: center;
  justify-content: center;
  min-height: 100px;
  max-height: 400px;
}

.portfolio-full-close {
  position: fixed;
  top: 0;
  right: 0;
  border: none;
  background: none;
  padding: 0;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  font-size: 75px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  cursor: pointer;
}

.portfolio-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  /* margin: 1rem; */
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  clip-path: content-box;
  cursor: pointer;
}

.portfolio-image-wrapper {
  width: 100px;
}

.portfolio-image {
  width: 100%;
  /* height: 100%; */
  transition: transform 1s;
}

.portfolio-image:hover {
  transform: scale(1.15);
}

.portfolio-full-carosuel {
  height: 85%;
  width: 70%;
  display: flex;
  padding-left: 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.portfolio-full-left,
.portfolio-full-right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-full-button {
  border: none;
  background: none;
  font-size: 60px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  width: 45px;
  cursor: pointer;
}

.portfolio-full-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-full-photo {
  /* height: 100%; */
  max-width: 100%;
  max-height: 100%;
}

.portfolio-full-text {
  height: 100%;
  width: 30%;
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  font-weight: 100;
  text-align: left;
  overflow-y: scroll;
}

.portfolio-full-text-block {
  height: 85%;
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 2rem 1rem;
  box-sizing: border-box;
}

.portfolio-full-text-title {
  font-weight: 600;
  font-size: 18pt;
}

.portfolio-full-text-desc {
  font-weight: 200;
  height: 60%;
  word-break: break-word;
}

@media (max-width: 850px) {
  .portfolio-full {
    flex-direction: column;
  }

  .portfolio-full-carosuel {
    height: 60%;
    width: fit-content;
    padding-left: 0;
  }

  .portfolio-full-text {
    height: 40%;
    width: 100%;
  }

  .portfolio-full-close {
    margin-right: 0;
    width: 65px;
    height: 65px;
    font-size: 50px;
  }
}