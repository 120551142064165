.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
  font-size: 10.5pt;
  margin: 1rem 2rem 0 2rem;
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.menu-item {
  position: relative;
}

.menu-link {
  width: 100%;
  /* color: #acacac; */
}

.sub-menu {
  position: absolute;
  background: white;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.6));
  /* top: 0;
  left: 0; */
  /* right: 100px; */
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: clamp(120px, 30vw, 250px);
}

.sub-menu-item {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  color: #000000;
}

.sub-menu-item:hover {
  background-color: #4d4d4d;
  color: #ffffff;
}