.soon-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.soon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(3px) grayscale(0.4) opacity(0.6)
}

.soon-cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.soon-text-group {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: radial-gradient(white 0%, white 40%, transparent 70%)
}

.soon-text {
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.soon-event {
  width: 80%;
  max-width: 620px;
  overflow: hidden;
}

.soon-image-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 45vw;
  height: 45vw;
  min-width: 350px;
  min-height: 350px;
  font-size: clamp(18px, 3vw, 36px);
  font-weight: 300;
  padding: 4px;
  box-sizing: border-box;
  margin: 1rem;
}

.soon-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  filter: contrast(0.8) grayscale(0.3) blur(2px);
  z-index: -1;
}

/* .soon-image-text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: clamp(18px, 3vw, 36px);
  font-weight: 300;
  padding: 4px;
  box-sizing: border-box;
} */

.soon-image-group>p {
  margin: 0;
  background: radial-gradient(rgba(225, 225, 225, 1) 0%, rgba(225, 225, 225, 0.6) 40%, transparent 75%);
  padding: 12px;
}

.soon-right-text {
  text-align: right;
}

.soon-title-text {
  color: black;
  font-size: clamp(24px, 4vw, 52px);
  background: radial-gradient(rgba(225, 225, 225, 1) 0%, rgba(225, 225, 225, 0.6) 40%, transparent 75%);
  padding: 5%;
  margin: 0;
  font-weight: 500;
}

.soon-bottom-text {
  font-size: clamp(14px, 2vw, 32px);
}