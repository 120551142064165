body {
  margin: 0;
  font-weight: 100;
  font-style: normal;
  font-size: 14pt;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  height: 100vh;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2 {
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: 30pt;
}

h2 {
  font-size: 20pt;
  color: #528289;
  margin-top: 0;
}

h4 {
  font-size: 11pt;
}

a {
  text-decoration: none;
  color: inherit;
}