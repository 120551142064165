.full-container {
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 100%;
  min-height: 100vh;
  /* align-items: center; */

}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content {
  margin: 1rem;
}

.header-text {
  position: relative;
  text-align: center;
  /* margin-bottom: 1em; */
}

.header-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: contrast(1.5) grayscale(0.6) opacity(0.7);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: -1;
}

.title {
  /* font-size: 42pt; */
  margin-top: 0.5em;
  margin-bottom: 0.1em;
  /* color: white;
  filter: drop-shadow(0 0 4px black) drop-shadow(0 0 12px #686868);
  background: radial-gradient(rgba(0, 0, 0, 0.1) 0%, transparent 60%); */
}

.subtitle {
  /* font-size: 18pt; */
  margin-top: 0.1em;
  font-weight: 300;
}