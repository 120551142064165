.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 2rem 0;
  font-size: clamp(10pt, 3vw, 12pt);
}

.social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  min-width: 200px;
  max-width: 300px;
  margin-bottom: 0.25rem;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 38%;
  min-width: 345px;
  max-width: 500px;
  margin: 0.25rem 0 0.5rem 0;
}

.icon {
  /* width: clamp(20px, 3vw, 35px); */
  min-width: 20px;
  width: 3vw;
  max-width: 35px;
}