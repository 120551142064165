.product-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  box-sizing: border-box;
  margin: 1rem;
}

.product-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  /* margin: 1rem; */
}

.product-images-lg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  /* margin: 1rem; */
}

.product-images-sm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  /* margin: 1rem; */
}

.product-img-lg {
  border: 1px solid black;
  height: 500px;
  width: 500px;
}

.product-img-sm {
  border: 1px solid black;
  height: 50px;
  width: 50px;
}

.product-images-info {
  width: 100%;
}

.product-title {
  width: 100%;
}

.product-price {
  width: 100%;
}