.faq-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  margin: 1rem;
}

.faq-root>p>span {
  color: #528289;
  font-weight: 300;
  font-size: 16pt;
}

.faq-img {
  width: 70%;
  max-width: 710px;
  min-width: 426px;
}