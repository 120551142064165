.tutorial-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  margin: 1rem;
}

.tutorial-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  margin: 1rem;
  flex-wrap: wrap;
}

.tutoral-video {
  width: 100%;
  height: clamp(300px, 75vh, 500px);
  max-width: 650px;
  flex-basis: 375px;
}

.video {
  width: 100%;
  height: 100%;
}

.tutorial-text {
  flex-basis: 375px;
  padding: 1em;
  box-sizing: border-box;
}

.tutorial-img {
  width: 70%;
  max-width: 710px;
  min-width: 426px;
}

.charity {
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.artist-credit {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 13.5pt;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.artist-name {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.1em;
  text-transform: uppercase;
}