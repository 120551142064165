.display-text {
  font-family: "Cinzel", serif;
  font-weight: 400;
  letter-spacing: 0.13em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 7px #fff);
  z-index: 1;
}

.display-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  max-width: 981px;
  max-height: 418px;
  margin: 0.75rem;
  font-size: clamp(22pt, 7vw, 36pt);
  filter: brightness(1);
  transition: filter 0.5s;
}

.display-container:hover {
  filter: brightness(1.5);
}

.display-font {
  font-size: 100%;
  transition: font-size 0.5s;
}

.display-font:hover {
  cursor: pointer;
  font-size: 102%;
}

.display-image {
  z-index: 0;
  width: 100%;
  height: 100%;
  filter: contrast(0.6) grayscale(0.3) blur(0.6px);
}

.display-soon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  letter-spacing: 4vw;
  transform: translate(0, -50%) rotate(345deg);
  font-size: clamp(11pt, 5vw, 36pt);
  font-weight: 400;
}